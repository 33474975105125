

.container {
    width: 100%;
    height: 1024px;

}

.chartContainer {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}

.iframeContainer {
    width: 100%;
    height: 100%;
    border: none;
}