
.item {
    width: 100px;
    font-size: 14px;
}

.downloadIcon {
    width: 20px;
    height: 20px;
    color: #0C77D8;
}

.downloadIconText {
    font-size: 14px;
    line-height: 24px;
    color: #0C77D8;
}