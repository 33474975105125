body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  color: #434343;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #0c77d8; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.5s linear infinite;
}

.customLoadingText {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #0C77D8;
}

.loadingText {
  width: 67px;
  height: 24px;
  /* Strong/Small */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #0c77d8;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

[contenteditable] {
  outline: 0 solid transparent;
}
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ababab;
  background-color: transparent;
}
