
.container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    gap: 1.5rem;
    border-bottom: 1px solid #D1D1D1;
    background: white;
}

.headerTitle {
    font-weight: 700;
    font-size: 32px;
    color: #434343;
    line-height: 40px;

}

/* top and bottom border px*/
.tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    padding-left: 1.5rem;
    border-bottom: 1px solid #D1D1D1;
    background: white;
}

/**
    underline height 2px
*/
.tabItem {
    cursor: pointer;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid transparent;
}

.tabItemSelected {
    cursor: pointer;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #0C77D8;
}

.tabItemTitle {
    color: #656565;
    font-size: 14px;
    font-weight: 700;
}

.tabItemTitleSelected {
    color: #0C77D8;
    font-size: 14px;
    font-weight: 700;
}

.selectContainer {
    width: 229px;
    cursor: pointer;
}

.selectContainer > div {
    margin-top: 0;
}

.selectContainer div[class*="MuiFormControl-root"] > div:first-child {
    margin-bottom: 0;
}

.daysSelectContainer {
    width: 458px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.daysSelectContainer > div {
    margin-top: 0;
}

.daysSelectContainer h5 + div {
    margin-bottom: 0;
}

.daysSelectContainerTitle {
    margin-top: 1.5rem;
}

.daysSelectContainerDescription {
    position: absolute;
    left: 0;
    bottom: -32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #888888;
}

.daysSelect {
    position: relative;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
}

.generateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid #D1D1D1;
}

.generateButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

/*//styleName: Button/Small/Default;*/
/*font-family: Manrope;*/
/*font-size: 14px;*/
/*font-weight: 700;*/
/*line-height: 24px;*/
/*text-align: left;*/

.clearText {
    color: #656565;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
}

.clearIcon {
    width: 20px;
    height: 20px;
    color: #656565;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.emptyContainerTitle {
    font-weight: 700;
    font-size: 32px;
    color: #434343;
    line-height: 40px;
}

.emptyContainerDescription {
    font-weight: 500;
    font-size: 16px;
    color: #656565;
    line-height: 24px;
}

.emptyContainerIcon {
    width: 60px;
    height: 60px;
    color: #0c77d8;
}

/**
    content overflow scroll y
*/
.tableContainer {
    /*background-color: #0C77D8;*/
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.historyHeader {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid #D1D1D1;
    height: 56px;
    padding-left: 24px;
}

.historyHeaderTitle {
    font-weight: 700;
    font-size: 20px;
    color: #434343;
    line-height: 28px;
}

.historyHeaderSubTitle {
    font-weight: 600;
    font-size: 12px;
    color: #888888;
    line-height: 20px;
}

.chip {
    width: fit-content;
    height: 20px;
    background: #0C77D8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px 2px 7px;
    margin-left: 8px;
}

.chipText {
    font-weight: 600;
    font-size: 12px;
    color: #FFF;
    line-height: 20px;
    text-align: left;
}

.chipDisabled {
    width: fit-content;
    height: 20px;
    background: #EFEFEF;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px 2px 7px;
    margin-left: 8px;
}

.chipTextDisabled {
    font-weight: 600;
    font-size: 12px;
    color: #888888;
    line-height: 20px;
    text-align: left;
}
