
.container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
    overflow-y: auto;
}

.header {
    height: 40px;
    background-color: #F8F8F8;
}

.headerTitle {
    font-weight: 700;
    font-size: 12px;
    color: #888888;
    line-height: 16px;
}

.row {
    height: 56px;
    background-color: #FFFFFF;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 12px;
}

.initialNameContainer {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: #F2C028;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.initialName {
    font-weight: 700;
    font-size: 10px;
    color: white;
    line-height: 14px;
}

.basicContent {
    font-weight: 500;
    font-size: 14px;
    color: #656565;
    line-height: 24px;
}

.chipContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 4px;
}

.chip {
    width: fit-content;
    height: 24px;
    background: #EDF6FF;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px 2px 7px;
}

.chipText {
    font-weight: 600;
    font-size: 12px;
    color: #0C77D8;
    line-height: 20px;
    text-align: left;
}

.chipSuccess {
    width: fit-content;
    height: 24px;
    background: #E8F6F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px 2px 7px;
}

.chipTextSuccess {
    font-weight: 600;
    font-size: 12px;
    color: #008479;
    line-height: 20px;
    text-align: left;
}

.table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.cell {
    height: 56px;
}


.toolTipContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 8px 16px;
    /*max-height: 300px;*/
    /*overflow-y: scroll;*/
}

/*//styleName: Body/Small;*/
/*font-family: Manrope;*/
/*font-size: 14px;*/
/*font-weight: 500;*/
/*line-height: 24px;*/
/*text-align: left;*/

.toolTipItemText {
    font-weight: 500;
    font-size: 14px;
    color: #656565;
    line-height: 24px;
    text-align: left;
}

.toolTipItemTextContainer {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

/*
    //styleName: Label/XSmall-subtle;
font-family: Manrope;
font-size: 12px;
font-weight: 700;
line-height: 16px;
text-align: left;

*/
.toolTipItemTitle {
    font-weight: 700;
    font-size: 12px;
    color: #888888;
    line-height: 16px;
    text-align: left;
}

/*
    box-shadow: 0px 6px 16px 0px #00000014;

box-shadow: 0px 0px 3px 0px #00000029;

*/
.toolTipItemTitleContainer {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    box-shadow: 0px 6px 16px 0px #00000014;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 0.5rem;
}

.loadingForItemContainer {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*gap: 0.5rem;*/
    /*background-color: red;*/
}

.loadingText {
    font-weight: 700;
    font-size: 16px;
    color: #0C77D8;
    line-height: 24px;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 0.625rem;
}

.emptyContainerTitle {
    font-weight: 700;
    font-size: 32px;
    color: #434343;
    line-height: 40px;
}

.emptyContainerDescription {
    font-weight: 500;
    font-size: 16px;
    color: #656565;
    line-height: 24px;
}

.emptyContainerIcon {
    width: 60px;
    height: 60px;
    color: #0c77d8;
}

.downloadIcon {
    width: 10px;
    height: 10px;
    color: #0C77D8;
}

.downloadIconText {
    font-size: 12px;
    color: #0C77D8;
}